import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/common/hero';
import heroImg from '../assets/img/l-1-visa-processing-time.jpg';
import CtaDeck from '../components/common/cta-deck';
import FeaturedIn from '../components/common/featured-in';
import SuccessStoriesDeck from '../components/common/Success-stories-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';
import { INVESTOR_VISA_IMMIGRATION_TESTIMONIALS } from '../data/investor-visa-usa-immigration/testimonials';
import ReviewDeck from '../components/common/review-deck';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import Reviewer from '../components/common/reviewer';
import Signature from '../components/common/signature';
import { prependStaticURL } from '../utils';

const headMeta = {
  title: 'L-1 Visa USA | Requirements & Application | Pandev Law',
  description:
    'Searching for a trusted and experienced L-1 Visa immigration lawyer? Pandev Law is here to help you. Talk to us now!',
  keywords:
    'investor immigration,investor visa USA,entrepreneur visa USA,US green card by investment',
};

const schema = `[
  {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "L-1 Visa USA | Requirements & Application | Pandev Law",
    "description": "Searching for a trusted and experienced L-1 Visa immigration lawyer? Pandev Law is here to help you. Talk to us now!",
    "url": "https://www.pandevlaw.com/l-1-visa",
    "image": "https://pandevlaw.com/img/l-1-visa-processing-time.jpg",
    "brand": "Pandev Law",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "0",
      "ratingCount": "246"
    },
    "sameAs": [
      "https://en-gb.facebook.com/pandevlaw/"
    ]
  },
  {
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.pandevlaw.com/l-1-visa/"
  },
  "headline": "L-1 Visa Lawyer Services",
  "description": ${JSON.stringify(headMeta.description)},
  "image": ${prependStaticURL(heroImg)},  
  "author": {
    "@type": "Person",
    "name": "Adrian Pandev",
    "url": "https://www.pandevlaw.com/about/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Pandev Law",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.pandevlaw.com/static/13437dd4aac9a31bf6176b69797f47be/61fd1/logo.webp"
    }
  },
  "datePublished": ""
}]`;

function E2Visa({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero title="L-1 Visa Lawyer Services" img={heroImg}>
        <p className="text-lead mb-5 mb-md-6">
          This is our dedicated L-1 visa lawyer services page. Below you will find
          information about the L-1 visa process, requirements, processing times, and
          advantages. As part of my L-1 visa lawyer services I will assist you with your
          L-1 visa application and guide you through your entire L-1 visa application
          process.
        </p>
      </Hero>
      <AbTestingDeck
        position="top"
        title="L-1 Visa Testimonials"
        testimonials={INVESTOR_VISA_IMMIGRATION_TESTIMONIALS}
      />
      <div className="section pt-5 pb-5 pt-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/what-is-l-1-visa.jpg"
                alt="What is L-1 Visa?"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <Reviewer />
              <h2 className="text-headline mb-5 mb-md-6">What is an L-1 visa?</h2>
              <p className="text-body mb-6">
                Generally, the L-1 visa is used for intercompany transfers of employees,
                but it can also be a great{' '}
                <Link to="/investor-visa-usa-immigration/">investor visa</Link> solution
                for individuals wishing to start a business in the United States. The L-1
                visa allows foreign companies that have established a subsidiary or
                affiliate company in the United States to relocate their managerial or
                executive staff or employees with specialized knowledge for a temporary
                stay in the United States. The L-1 visa is also a great option for foreign
                business owners who wish to relocate to start a new business in the United
                States. Such investors can apply for an L-1 new office visa as a manager
                or executive who will relocate to the United States to establish a new US
                branch office or a new US affiliate company.
                <br />
                <br />
                If you would like to obtain more information about the L-1 visa program,
                please see below explanation of the requirements and the L-1 visa process.
                If you are wondering whether you qualify for an L-1 visa, as an
                experienced L-1 visa lawyer, I would gladly provide you with my honest
                assessment of your case and give you a recommendation about your next
                steps.
              </p>
              <div className="accordion" id="corporateLaw">
                <div className="mb-5">
                  <div id="corporateLawHeadingOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#corporateLawCollapseOne"
                      aria-expanded="true"
                      aria-controls="corporateLawCollapseOne">
                      <strong>L-1 visa advantages</strong>
                    </h2>
                  </div>
                  <div
                    id="corporateLawCollapseOne"
                    className="collapse show"
                    aria-labelledby="corporateLawHeadingOne"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      The L-1 visa is one of the most desirable investor and business visa
                      solutions due to its many advantages and few limitations compared to
                      other visa categories. Below is a summary of the L-1 visa
                      advantages:
                    </p>
                    <div className="mt-3">
                      <strong>No quota limit for L-1 visa</strong>
                      <p className="text-body mt-3">
                        There is no limitation on the number of L-1 visas issued per
                        fiscal year. This means that there is no quota limit as there is
                        with some nonimmigrant visa categories, such as the H-1B. An L-1
                        visa applicant may apply for an L-1 visa any time of the year.
                      </p>
                    </div>
                    <div className="mt-3">
                      <strong>
                        Your family can move to the US with you on an L-2 visa
                      </strong>
                      <p className="text-body mt-3">
                        The L-1 visa program allows the visa holder to bring her spouse
                        and unmarried children under 21 to the United States. Family
                        members are classified as dependents and are issued an L-2 visa.
                        The great advantage of the L-2 dependent visa is that it allows
                        the spouse of an L-1 visa holder to obtain an Employment
                        Authorization Document (EAD). This allows the L-2 spouse to work
                        in the United States. This is a big advantage comparing to other
                        nonimmigrant visa categories, such as the H-1B, which do not
                        permit spouses of the principal visa holder to work in the United
                        States. Unmarried children under 21 may obtain an L-2 dependent
                        visa and enroll in school in the United States. To find out more
                        about the L-2 dependent visa, please see the L-2 visa section
                        below.
                      </p>
                    </div>
                    <div className="mt-3">
                      <strong>Dual intent nature of L-1 visa</strong>
                      <p className="text-body mt-3">
                        One of the biggest advantages of the L-1 visa is that it is a dual
                        intent visa. This permits L-1 visa holder to apply for permanent
                        residence in the United States without additional hurdles faced by
                        other nonimmigrant visa holders. If you are an L-1 visa holder and
                        working as a manager in the United States, you may also qualify
                        for an EB-1 multinational manager green card. As part of our L-1
                        visa lawyer services, we represent individuals through both the
                        L-1A manager visa and EB-1 multinational manager green card
                        processes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row mt-5">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/l-1-visa-advantages.jpg"
                alt="L-1 visa requirements"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">L-1 visa requirements</h2>
              <p className="text-body mb-6">
                To qualify for an L-1 visa, the applicant must meet all the requirements.
                Please see below a summary of the L-1 visa requirements for intracompany
                transfers of employees, and requirements for investors who wish to
                establish an office or a branch of their company in the United States.
              </p>
              <div className="accordion" id="l1VisaRequirements">
                <div className="mb-5">
                  <div id="l1VisaRequirementsHeadingZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#l1VisaRequirementsCollapseZero"
                      aria-expanded="true"
                      aria-controls="l1VisaRequirementsCollapseZero">
                      <strong>Qualifying relationship with foreign company</strong>
                    </h2>
                  </div>
                  <div
                    id="l1VisaRequirementsCollapseZero"
                    className="collapse show"
                    aria-labelledby="l1VisaRequirementsHeadingZero"
                    data-parent="#l1VisaRequirements">
                    <div className="my-4">
                      <p className="text-body mt-4">
                        According to the L-1 visa program rules, there must be a
                        qualifying relationship between the foreign company that initiates
                        the petition and the US company where the L-1 visa applicant will
                        work. A “qualifying relationship” means that you must prove that
                        your US company is a subsidiary, branch, or other affiliate of
                        your foreign company. Two companies will be considered
                        “affiliates” if they share the same control group.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="l1VisaRequirementsHeadingOne">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#l1VisaRequirementsCollapseOne"
                      aria-expanded="false"
                      aria-controls="l1VisaRequirementsCollapseOne">
                      <strong>Doing business both in the United States and abroad</strong>
                    </h2>
                  </div>
                  <div
                    id="l1VisaRequirementsCollapseOne"
                    className="collapse"
                    aria-labelledby="l1VisaRequirementsHeadingOne"
                    data-parent="#l1VisaRequirements">
                    <p className="text-body mt-4">
                      The L-1 visa rules require that your company be doing business in
                      both the United States and a foreign country for at least the
                      duration of your L-1 visa stay. The term “doing business” means the
                      “regular, systematic, and continuous provision of goods and/or
                      services.” Establishing an office or the presence of an agent alone
                      will not be considered “doing business” and will not meet this L-1
                      visa requirement.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="l1VisaRequirementsHeadingTwo">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#l1VisaRequirementsCollapseTwo"
                      aria-expanded="false"
                      aria-controls="l1VisaRequirementsCollapseTwo">
                      <strong>
                        For L-1A Managers - Employed as a manager or executive in foreign
                        company
                      </strong>
                    </h2>
                  </div>
                  <div
                    id="l1VisaRequirementsCollapseTwo"
                    className="collapse"
                    aria-labelledby="l1VisaRequirementsHeadingTwo"
                    data-parent="#l1VisaRequirements">
                    <div className="my-4">
                      <p className="text-body mt-4">
                        To qualify for an L-1A visa, the applicant must have worked in a
                        managerial or executive capacity for the foreign company for 1
                        continuous year within the last 3 years immediately preceding
                        admission to the United States in L-1A status.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="l1VisaRequirementsHeadingThree">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#l1VisaRequirementsCollapseThree"
                      aria-expanded="false"
                      aria-controls="l1VisaRequirementsCollapseThree">
                      <strong>
                        For L-1A Managers - Employed as a manager or executive in US
                        business
                      </strong>
                    </h2>
                  </div>
                  <div
                    id="l1VisaRequirementsCollapseThree"
                    className="collapse"
                    aria-labelledby="l1VisaRequirementsHeadingThree"
                    data-parent="#l1VisaRequirements">
                    <div className="my-4">
                      <p className="text-body mt-4">
                        The L-1 visa rules require that the L-1A visa applicant enter the
                        United States to work in a managerial or executive capacity. The
                        L-1A visa holder will be required to work for the US employer in a
                        managerial or executive capacity for the duration of her stay in
                        L-1A status. While the US company may hire additional managers to
                        operate the US business, the L-1A manager cannot hold a completely
                        hands-off role in the US company.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="l1VisaRequirementsHeadingSix">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#l1VisaRequirementsCollapseSix"
                      aria-expanded="false"
                      aria-controls="l1VisaRequirementsCollapseSix">
                      <strong>
                        L-1B Specialized Knowledge - Employees must demonstrate
                        specialized knowledge
                      </strong>
                    </h2>
                  </div>
                  <div
                    id="l1VisaRequirementsCollapseSix"
                    className="collapse"
                    aria-labelledby="l1VisaRequirementsHeadingSix"
                    data-parent="#l1VisaRequirements">
                    <p className="text-body mt-4">
                      To qualify for an L-1B visa, the applicant must have worked for the
                      foreign entity for 1 continuous year within the last 3 years. The
                      L-1B visa applicant must also demonstrate that she is seeking
                      admission to the United States to work in a specialized knowledge
                      capacity for the US entity.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="l1VisaRequirementsHeadingFour">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#l1VisaRequirementsCollapseFour"
                      aria-expanded="false"
                      aria-controls="l1VisaRequirementsCollapseFour">
                      <strong>
                        Physical premise requirement for L-1A new office applications
                      </strong>
                    </h2>
                  </div>
                  <div
                    id="l1VisaRequirementsCollapseFour"
                    className="collapse"
                    aria-labelledby="l1VisaRequirementsHeadingFour"
                    data-parent="#l1VisaRequirements">
                    <p className="text-body mt-4">
                      In order to qualify for an L-1 visa, you must prove that your US
                      company has secured sufficient physical premises for its US
                      operation.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="l1VisaRequirementsHeadingFive">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#l1VisaRequirementsCollapseFive"
                      aria-expanded="false"
                      aria-controls="l1VisaRequirementsCollapseFive">
                      <strong>
                        Ability to support executive or managerial position for L-1A new
                        office applications
                      </strong>
                    </h2>
                  </div>
                  <div
                    id="l1VisaRequirementsCollapseFive"
                    className="collapse"
                    aria-labelledby="l1VisaRequirementsHeadingFive"
                    data-parent="#l1VisaRequirements">
                    <p className="text-body mt-4">
                      According to the L-1 visa rules, if you are applying for an L-1A new
                      office visa, you must demonstrate that your new US operation will
                      grow to a sufficient size to support an executive or managerial
                      position within 1 year of approval of your L-1 visa application.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/l-1a-l-1b-difference.jpg"
                alt="Difference between an L-1A and L-1B visa?"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">
                Difference between an L-1A and L-1B visa?
              </h2>
              <p className="text-body mb-5">
                The two types of L-1 visas are the L-1A and L-1B visa.
                <br />
                <br />
                The L-1A visa is granted to individuals employed as
                <strong>managers</strong> or <strong>executives</strong> at the foreign
                entity who enter the United States to work in the same capacity in the
                company’s US office. If the company does not have any branches or
                affiliates in the US, it can still benefit from an L-1A visa by sending a
                manager or an executive to establish a new office in the United States.
                <br />
                <br />
                Having the title “manager” does not immediately qualify someone for an
                L-1A visa. According to the L-1 visa rules, to qualify as a manager, an
                individual must be: (1) <strong>head</strong> of a key department within
                the business entity; (2) <strong>supervisor</strong> of higher-level
                workers in professional, managerial or supervisory capacity within the
                business entity; (3) <strong>authorized</strong> to hire, fire and promote
                employees within the business entity; or (4)
                <strong>manage</strong> the day to day operations of the business entity.
                <br />
                <br />
                To qualify as an “executive” under the L-1 visa rules, you must show that
                the individual: (1)
                <strong>directs</strong> the management of the business entity; (2){' '}
                <strong>establishes</strong> the policies and objectives of the business
                entity; or (3) <strong>supervises</strong> high-level employees and only
                receives general supervision from higher level executives of the business
                entity.
                <br />
                <br />
                In contrast to the L-1A visa, the L-1B visa is granted to
                <strong>specialized knowledge</strong> employees who have an extensive
                comprehension of the company’s services, products, research and other
                important proprietary information. As an L-1B visa applicant, you must
                demonstrate your specialized knowledge and why the US entity needs your
                services in this specialized knowledge capacity.
              </p>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/l-1-visa-application-process.jpg"
                alt="L-1 visa application process"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">L-1 visa application process</h2>
              <p className="text-body mb-4">
                There are two ways to apply for an L-1 visa: regular application process
                and a blanket petition.
              </p>
              <p className="text-body mb-4">
                <strong>Regular L-1 visa application process</strong>
              </p>
              <ol className="corporateLawOrderedList">
                <li className="noBold mb-4">
                  <i>Submitting Form I-129, Petition for a Nonimmigrant Worker</i>
                  <p className="text-body mt-4">
                    First, the US entity must submit an L-1 petition on Form I-129,
                    Petition for a Nonimmigrant Worker, with USCIS. A USCIS officer will
                    review the petition and make a decision whether the company and the
                    beneficiary meet the L-1 visa requirements. Processing times for L-1
                    visa petitions range from 6 to 8 months. The sponsoring L-1 visa
                    company can request premium processing service for the L-1 petition
                    which guarantees a response from USCIS in 15 days.
                  </p>
                </li>
                <li className="noBold mb-4">
                  <i>Submitting Form DS-160, Nonimmigrant visa application</i>
                  <p className="text-body mt-4">
                    If the L-1 beneficiary is outside the United States when the L-1
                    petition is approved with USCIS, she will need to submit Form DS-160,
                    Nonimmigrant Visa Application, with the nearest US Consulate or
                    Embassy. The consular officer will review the application and conduct
                    an interview to assess whether the applicant meets the L-1 visa
                    requirements. Once the consular officer approves the application,
                    USCIS will issue her an L-1 visa, which she can use to enter the
                    United States and start her employment.
                  </p>
                </li>
              </ol>
              <p className="text-body mb-4">
                <strong>L-1 visa application through blanket petition</strong>
              </p>
              <p className="text-body mb-4">
                The blanket petition option allows companies to transfer several employees
                to the United States with only one L-1 visa petition. The main difference
                is that the company files one L-1 blanket petition explaining its need to
                transfer numerous workers from its foreign operations to the United
                States. Once the L-1 blanket petition is approved, the US petitioner sends
                each applicant the blanket L-1 visa approval notice along with a completed
                Form I-129S. The L-1 visa applicant can immediately apply for an L-1 visa
                at the nearest US Consulate or Embassy using the blanket petition approval
                notice and Form I-129. This process is significantly faster than having to
                file separate L-1 petitions for each employee.
                <br />
                <br />
                Please keep in mind, the L-1 visa blanket petition option is only
                available to companies that meet certain requirements. The blanket L-1
                visa requirements are:
              </p>
              <ul className="list-bullets mb-4">
                <li>The petitioner is engaged in commercial trade or services;</li>
                <li>
                  The petitioner must have an office in the United States that has been in
                  operation for at least one year;
                </li>
                <li>
                  The petitioner must have at least three other domestic and foreign
                  branches or other affiliates; and
                </li>
                <li>
                  The petitioner and the foreign entity meet one of the following
                  criteria:
                  <ul className="list-bullets-circle my-4">
                    <li>The petitioner must have at least 1,000 US employees;</li>
                    <li>
                      The petitioner and its affiliates must have combined annual revenue
                      of at least $25 million; or
                    </li>
                    <li>
                      The petitioner must have successfully been approved for at least 10
                      L-1 visa approvals in the 12 months prior to filing the blanket
                      petition.
                    </li>
                  </ul>
                </li>
              </ul>
              <p className="text-body mb-4">
                As part of our L-1 visa lawyer services, we will assist you with your L-1
                visa application, guide you through your entire L-1 visa application
                process and advise you every step of the way.
              </p>
              <div className="accordion" id="internationalBusinessLaw">
                <div className="mb-5">
                  <div id="treatyTraderVisaZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#treatyTraderVisaTargetZero"
                      aria-expanded="true"
                      aria-controls="treatyTraderVisaTargetZero">
                      <strong>L-1 visa duration</strong>
                    </h2>
                  </div>
                  <div
                    id="treatyTraderVisaTargetZero"
                    className="collapse show"
                    aria-labelledby="treatyTraderVisaZero"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body mb-4">
                      The maximum duration granted for an L-1A visa is 7 years and for
                      L-1B visa the maximum allowed stay is 5 years. Generally, U.S.
                      Citizenship and Immigration Services (USCIS) issues L-1 visas for an
                      initial 3-year period. The L-1 visa holder can then apply for an
                      extension in 2-year increments until the maximum time is reached.
                      This means that an L-1A visa holder may extend her visa twice until
                      the maximum allowed stay is reached, and an L-1B visa holder may
                      extend her visa only once until the maximum L-1 visa stay is
                      reached.
                      <br />
                      <br />
                      In the case of a new office L-1A petition, the initial stay is
                      limited to 1 year. A new office L-1A visa holder may also extend her
                      visa in increments of 2 years until the maximum stay of 7 years is
                      reached.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="treatyTraderVisaOne">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#treatyTraderVisaTargetOne"
                      aria-expanded="false"
                      aria-controls="treatyTraderVisaTargetOne">
                      <strong>L-1 visa processing time</strong>
                    </h2>
                  </div>
                  <div
                    id="treatyTraderVisaTargetOne"
                    className="collapse"
                    aria-labelledby="treatyTraderVisaOne"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body mb-4">
                      Generally, the L-1 visa processing time depends on USCIS’s workload
                      and the country in which the L-1 visa applicant submits her
                      application. Usually, the processing time for the L-1 petition (Form
                      I-129) takes approximately 6 months. The processing time of the L-1
                      visa application (Form DS-160) at a US Consulate or Embassy can take
                      anywhere from 2 to 6 months, depending on the workload of the
                      Consulate or Embassy.
                      <br />
                      <br />
                      USCIS offers a premium processing service which guarantees a
                      response to the L-1 visa petition within 15 calendar days. The cost
                      for the premium processing service is $1,440 and it does not
                      guarantee that the L-1 visa application will be approved. If USCIS
                      takes longer than 15 calendar days to process the L-1 visa
                      application, USCIS will reimburse the filing fee.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="treatyTraderVisaTwo">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#treatyTraderVisaTargetTwo"
                      aria-expanded="false"
                      aria-controls="treatyTraderVisaTargetTwo">
                      <strong>L-1 visa extension process</strong>
                    </h2>
                  </div>
                  <div
                    id="treatyTraderVisaTargetTwo"
                    className="collapse"
                    aria-labelledby="treatyTraderVisaTwo"
                    data-parent="#internationalBusinessLaw">
                    <p className="text-body mb-4">
                      Generally, the L-1 visa holder needs to meet the following
                      requirements to obtain an L-1 visa extension:
                    </p>
                    <ul className="list-bullets mb-4">
                      <li>Valid L-1 visa status when filing for the extension;</li>
                      <li>
                        Clean criminal record throughout the stay in the United States;
                      </li>
                      <li>
                        Valid passport for the rest of the extension period in the United
                        States;
                      </li>
                      <li>
                        The L-1 visa holder must have complied with all the conditions of
                        admission into the United States from the time of the initial L-1
                        visa application.
                      </li>
                    </ul>
                    <p className="text-body mb-4">
                      The L-1 visa extension process is similar to the initial L-1 visa
                      application process. The L-1 petitioning company files Form I-129
                      with USCIS. If the L-1 visa holder is in the United States, she can
                      apply for an extension of status in the United States. If she is
                      located outside the United States, she may apply for a new L-1 visa
                      at a US Consulate or Embassy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/what-is-l-2-visa.jpg"
                alt="What is an L-2 visa?"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">What is an L-2 visa?</h2>
              <p className="text-body mb-5">
                Spouses and unmarried children under 21 of L-1 visa holders can also
                benefit from the principal applicant’s L-1 visa status. Spouses and
                unmarried children under 21 of L-1 visa applicants are eligible to apply
                for an L-2 dependent visa and travel to the United States along with the
                principal L-1 visa applicant. The L-2 visa applicants may apply for their
                visa at the same time as the principal L-1 visa applicant so that they can
                relocate to the United States together.
                <br />
                <br />
                Spouses of L-1 visa holders who hold valid L-2 status may apply for an
                Employment Authorization Document (EAD). This document permits L-2 spouses
                to work in the United States without the need for an employer sponsor.
              </p>
              <strong>L-2 visa duration</strong>
              <p className="text-body mb-5 mt-3">
                The L-2 visa has the same initial and maximum duration periods as the
                principal L-1 visa. This means that the spouse and children under 21 of an
                L-1 visa holder will be allowed to remain in the United States for the
                same period as the principal L-1 visa holder. The spouse and children
                under 21 of an L-1A visa holder should be admitted for an initial period
                of 3 years and may extend their L-2 visas in 2-year increments for a
                maximum of 7 years. Similarly, the spouse and children under 21 of an L-1B
                visa holder should be admitted for an initial period of 3 years and may
                extend their L-2 visas one time for 2 years for a maximum stay of 5 years.
                L-2 visa holders will not be granted an automatic extension based on the
                L-1 visa holder’s status. L-2 visa holders must apply for an extension of
                their status before the expiration of their authorized period of stay in
                L-2 status.
              </p>
            </div>
          </div>
          <Signature />
        </div>
      </div>
      <AbTestingDeck
        title="L-1 Visa Testimonials"
        testimonials={INVESTOR_VISA_IMMIGRATION_TESTIMONIALS}
      />
      <PilarNavigation />
      <CtaDeck />
      <SuccessStoriesDeck />
    </Layout>
  );
}

export default E2Visa;
